@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  position: relative;
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusXLarge);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.userAspectRatioWrapper {
  position: relative;
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.userRootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusXLarge);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);
  color: #737373;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: #737373;
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.userMainInfo {
  display: flex;
  flex-direction: column;

  padding: 0;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.socialMedia {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.socialItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 4px;
  background-color: #fff5f5;
  border: 1px solid #f5dcdc;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  color: #333;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
}

.instagramIcon {
  color: #e1306c;
  font-size: 24px;
}

.youtubeIcon {
  color: #ff0000;
  font-size: 24px;
}

.facebookIcon {
  color: #3b5998;
  font-size: 24px;
}

.whatsappIcon {
  color: #25d366;
  font-size: 24px;
}

.linkedinIcon {
  color: #0077b5;
  font-size: 24px;
}

.twitterIcon {
  color: #000;
  font-size: 24px;
}

.nameLocation {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.name {
  color: var(--colorWhite);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.userInfo {
  background: var(--colorWhite);
  
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  margin: 16px 0;

  @media (--viewportMedium) {
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  background-color: #f5f7ff;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  color: #555;
  font-weight: 500;
  cursor: default;
  transition: box-shadow 0.2s ease;
}

.tagIcon {
  margin-right: 5px;
}

.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 100%;
  width: 100%;
  gap: 16px;

  @media (--viewportMedium) {
    margin-top: 0;
    width: 30%;
  }
}

.viewProfileButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #333;
  border: 1px solid #333;
  padding: 12px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  
  height: fit-content;
  width: 100%;
}

.viewProfileButton:hover {
  background-color: #f4f4f4;
  text-decoration: none;
}

.collabButton {
  background-color: #EB2930;
  color: #fff;
  border: none;
  padding: 12px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  height: fit-content;
  width: 100%;
}

.collabButton:hover {
  background-color: #e04747;
}

.location {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--colorWhite);
}

.socialVerified {
  color: #00bfff;
  fill: #00bfff;
}

.greenVerified {
  color: #25d366;
  fill: #25d366;
}

.niche {
  margin: 0;
}

.notFavourite {
  background: var(--colorBlack);
  color: var(--colorWhite);
}

.notFavourite:hover {
  background: var(--colorBlack);
  color: var(--colorWhite);
}

.category {
  margin: 0;
}

.professionalCreator {
  display: flex;
  align-items: center;
  
  font-size: 12px;
  padding: 4px 8px;
  background: var(--marketplaceColor);
  color: var(--colorWhite);
  border-radius: 8px;

  width: fit-content;

  position: absolute;
  top: 16px;
  left: 16px;

  @media (--viewportLarge) {
    position: relative;
  }
}


.whiteVerifiedIcon {
  color: var(--colorWhite);
  fill: var(--colorWhite);
  margin-right: 5px;
  width: 24px;
  height: 24px;
} 